import throttle from "lodash.throttle"

const doc = document.documentElement;
let prevScroll = window.scrollY || doc.scrollTop;
let curScroll;
let direction = 0;
let prevDirection = 0;

const header = document.querySelector('.site-header');

function headerScroller() {
    if (header) {
        const headerHeight = header.clientHeight + 2;
        
				// TODO: Consider refactoring to move function outside of parent function
        function headerScroll() {
            
            if (window.scrollY < headerHeight / 2) {
                header.style.top = '-' + headerHeight + 'px';
                header.classList.remove('border-navy-20');
            }
        
            curScroll = window.scrollY || doc.scrollTop;
            
            if (curScroll > prevScroll) { 
              //scrolled up
              direction = 2;
            } else if (curScroll < prevScroll) { 
              //scrolled down
              direction = 1;
            }
            
            if (direction !== prevDirection) {
              toggleHeader(direction, curScroll);
            }
        
            prevScroll = curScroll;
        }
        // TODO: Consider refactoring to move function outside of parent function
        function toggleHeader(direction, curScroll) {
            
            const headerSearch = document.getElementById('header-search-container');
            const openMenu = document.querySelector('.nav-item--open');
                                
            if (headerSearch.hidden && !openMenu) {
                if (direction === 2 && curScroll > headerHeight) { 
                    header.style.top = '-' + headerHeight + 'px';
                    header.classList.add('border-navy-20');
                    prevDirection = direction;  
                } else if (direction === 1) {
                    header.style.top = '0px';
                    header.classList.add('border-navy-20');
                    prevDirection = direction;   
                } 
            } else {
                header.style.top = '0px';
                header.classList.add('border-navy-20');
                prevDirection = 1;     
            }
        }
        
        window.addEventListener('scroll', throttle(headerScroll, 50), false);
        window.addEventListener('resize', throttle(headerScroll, 50), false);
    }
}

export default headerScroller;
