import axios from "axios/dist/axios"

const init = () =>
{
	// Clear interval if already set
	if (typeof dispatchInterval !== 'undefined')
	{
		clearInterval(dispatchInterval)
	}
	
	// Check document for delivery message elements
	const els = document.querySelectorAll('[data-dispatch-countdown]')

	// If at least one exists...
	if (els.length)
	{
		// Every 10 seconds, make ajax call to get new delivery message
		const dispatchInterval = setInterval( () => {

			axios.get('/actions/orders/default/get-dispatch-countdown-text')
			.then(function (response)
			{
				// If response ok
				if (response.status === 200)
				{
					const data = response.data

					if (data.result)
					{
						// Loop through delivery message elements and insert new message from response
						for (const el of els)
						{					
							el.innerHTML = data.data.prefix + ' <strong>' + data.data.period + '</strong>'
						}
					}
				}
			})
			.catch(function (error)
			{
				console.log(error)
			})

		}, 10000)
	}
}

export default init