import smoothscroll from 'smoothscroll-polyfill';
/**
 * title Timeline Slider
 * description Set active items on table of content on scroll
 */ 
function timelineSlider() {
    const timeline = document.getElementById('timeline');
    if (document.body.contains(timeline)) {
        const scrollLocation = window.pageYOffset;
        const timelineSlides = document.querySelectorAll('.timeline-slide');
        const timelineYears = timeline.querySelector('#timeline-years');
            
        for (let i = 0; i < timelineSlides.length; i++) {
            timelineSlides[i].style.minHeight = timelineYears.clientHeight + 'px';
            const year = document.querySelector('[data-year="'+ timelineSlides[i].id +'"]');
            if (year) {
                const siblings = getSiblings(year);
                let sectionOffset = timelineSlides[i].offsetTop - 200;                
                if (sectionOffset <= scrollLocation) {
                    year.classList.add('heading-h2', 'font-semibold', 'active'); 
                    for (let i = 0; i < siblings.length; i++) {
                        siblings[i].classList.remove('heading-h2', 'font-semibold', 'active');
                    }      
                }
            }
        }            
    }
};

/**
 * title Smooth scrolling
 * description Smooth scroll for table content
 */ 
function timelineScroll() {
    const timeline = document.getElementById('timeline');
    if (document.body.contains(timeline)) {
        const years = document.querySelectorAll("[data-year]");
         
        for (const year of years) {
          year.addEventListener("click", clickHandler);
        }
         
        function clickHandler(e) {
            e.preventDefault();
            const href = this.getAttribute("data-year");
            const offsetTop = document.getElementById(href.replace('#', '')).offsetTop - 100;
            
            scroll({
                top: offsetTop,
                behavior: "smooth"
            });
        
            smoothscroll.polyfill();
        
        }
    
    }
}

function getSiblings(elem) {
    let siblings = [];
    let sibling = elem.parentNode.firstChild;
        
    while (sibling) {
        if (sibling.nodeType === 1 && sibling !== elem) {
            siblings.push(sibling);
        }
        sibling = sibling.nextSibling    
    }
    return siblings;
};

export { timelineSlider, timelineScroll };

export default timelineSlider;