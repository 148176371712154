import LazyLoad from "vanilla-lazyload";

const desktopMenuContainer = document.querySelector('[data-menu="main"]');

if (desktopMenuContainer) {
    desktopMenuContainer.addEventListener('keydown', function(event) {
        keyboardNavigation(event);
    });
}

var lazyLoadMenu = new LazyLoad({
    elements_selector: ".menu-lazy",
    cancel_on_exit: false,
    unobserve_entered: true,
});

function addLazyClass(element) {
    const images = element.querySelectorAll('.menu-image');
    images.forEach(function(item) {
        if (!item.classList.contains('menu-lazy')) {
            item.classList.add('menu-lazy'); 
            lazyLoadMenu.update(); 
        }     
    });
}


/**
 * @title Navigation controller
 * @description Based on key input decide what actions should be taken to control
 * menu
 *
 * @param {event} event
 */
function keyboardNavigation(event) {
    const classList = {
        hasChildren: 'nav-item--children',
        openedLi: 'nav-item--open',
        expandedUl: 'nav__active',
    }
    const activeElement = document.activeElement;
    const parentLi = getClosestParentTag(activeElement, 'LI');
		
    switch (event.keyCode) {
        case 27: // Escape
            if (isSubMenu(activeElement)) {
                closeSubMenu(parentLi, classList);
            } else {
                closeMainMenu(parentLi, classList);
            }
            break;

        case 37: // Left Arrow
            if (isSubMenu(activeElement)) {
                navSubMenuSideways('left', activeElement, parentLi, classList);
            } else {
                navMainMenuSideways('left', parentLi, classList);
            }
            break;

        case 38: // Up Arrow
            event.preventDefault();
            if (isSubMenu(activeElement) && parentLi.previousElementSibling !== null) {
                navSubMenuUp(parentLi);
            } else {
                closeMainMenu(parentLi.closest('.nav-item--open'), classList, true);
            }
            break;

        case 39: // Right Arrow
            if (isSubMenu(activeElement)) {
                navSubMenuSideways('right', activeElement, parentLi, classList);
            } else {
                navMainMenuSideways('right', parentLi, classList);
            }
            break;
        case 40: // Down Arrow
            event.preventDefault();
                        
            if (!isSubMenu(activeElement)) {
                navMainMenuDown(activeElement, parentLi, classList);
            } else if (parentLi.nextElementSibling && parentLi.nextElementSibling.nodeName === 'LI') {
                parentLi.nextElementSibling.querySelector('a').focus();
            }

            break;
        case 9: // Tab
            if (parentLi.nextElementSibling === null) {
                if (parentLi.parentNode.nextElementSibling === null) {
                    const grandParent = getClosestParentTag(parentLi.parentNode.parentNode, 'UL');
                    grandParent.classList.remove(classList.expandedUl);
                    grandParent.parentNode.setAttribute('aria-hidden', true);
                    grandParent.parentNode.classList.remove('nav-item--open');
                }
            }

            break;

						// TODO: Switch statements should always have a default case
    }
}

/**
 * @description Close main menu
 *
 * @param {element} activeElement
 * @param {object} classList
 */
function closeMainMenu(activeElement, classList, nextSibling) {
    const subMenu = activeElement.querySelector('[data-menu="submenu"]');

    if (subMenu) {
        activeElement.classList.remove(classList.openedLi);
        subMenu.classList.remove(classList.expandedUl);

        if (nextSibling === true) {
            activeElement.querySelector('a').focus();
        }
    }
}

/**
 * @description Closing sub menu
 *
 * @param {element} activeElement
 * @param {object} classList
 */
function closeSubMenu(activeElement, classList) {
    const parentUl = getClosestParentTag(activeElement, 'UL');
    const parentMenu = getClosestParentTag(parentUl, 'LI');

    parentMenu.classList.remove(classList.openedLi);
    parentUl.classList.remove(classList.expandedUl);
    parentMenu.querySelector('a').focus();
}

/**
 * @description Opening main or sub menu
 *
 * @param {element} activeElement
 * @param {object} classList
 */
function openMenu(activeElement, classList) {
    const subMenu = activeElement.querySelector('[data-menu="submenu"]');
    if (subMenu) {
        activeElement.classList.add(classList.openedLi);
        subMenu.classList.add(classList.expandedUl);
    }
    addLazyClass(activeElement);
}

/**
 * @description Decide what to do when key left or right is being pressed while
 * focus is on element that is in main menu
 *
 * @param {string} direction
 * @param {element} parentLi
 * @param {object} classList
 */
function navMainMenuSideways(direction, parentLi, classList) {
    const nextActiveElement = (direction === 'right') ?
        parentLi.nextElementSibling :
        parentLi.previousElementSibling;

    if (nextActiveElement !== null) {
        nextActiveElement.querySelector('a').focus();
    }

    if (parentLi.classList.contains(classList.openedLi)) {
        closeMainMenu(parentLi, classList);
        openMenu(nextActiveElement, classList);
    }
}

/**
 * @description Decide what to do when key left or right is being pressed while
 * focus is on element that is in sub menu
 *
 * @param {string} direction
 * @param {element} activeElement
 * @param {element} parentLi
 * @param {object} classList
 */
function navSubMenuSideways(direction, activeElement, parentLi, classList) {
    const parentMenu = getClosestParentTag(parentLi.parentNode, 'LI');

    switch (direction) {
        case 'right':
            if (hasSubMenu(activeElement, classList)) {
                const firstSubMenu = parentLi.querySelector('[data-menu="submenu"] ul > li');

                openMenu(parentLi, classList);
                firstSubMenu.querySelector('a').focus();
            }
            break;

        case 'left':

            if (isSubMenu(parentMenu)) {
                closeSubMenu(parentLi, classList);
                parentMenu.querySelector('a').focus();
            }
            break;
    }
}

/**
 * @description Decide what to do when key up is being pressed while focus is on
 * element that is in sub menu
 *
 * @param {element} parentLi
 */
function navSubMenuUp(parentLi) {
    const parentMenu = getClosestParentTag(parentLi.parentNode, 'LI');

    if (parentLi.previousElementSibling.nodeName === 'LI') {
        parentLi.previousElementSibling.querySelector('a').focus();
    } else if (!isSubMenu(parentMenu)) {
        parentMenu.querySelector('a').focus();
    }

}

/**
 * @description Decide what to do when key down is being pressed while focus is
 * on element that is in main menu
 *
 * @param {element} activeElement
 * @param {element} parentLi
 * @param {object} classList
 */
function navMainMenuDown(activeElement, parentLi, classList) {
    const openedMenu = parentLi.classList.contains(classList.openedLi);
    if (hasSubMenu(activeElement, classList)) {
        if (!openedMenu) {
            openMenu(parentLi, classList);
            desktopMenuContainer.classList.add('navigation--open');
        } else {
            const firstSubMenu = parentLi.querySelector('[data-menu="submenu"] ul > li');
            firstSubMenu.querySelector('a').focus();
        }
    }
}

/**
 * @description Traverse the DOM upwards to find specified tag
 *
 * @param {element} activeElement
 * @param {string} tagName
 * @returns
 */
function getClosestParentTag(activeElement, tagName) {
    let parentElement = activeElement;

    if (parentElement.classList.contains('menu-link')) {
        while (parentElement.nodeName !== tagName) {
            parentElement = parentElement.parentNode;
        }
    }

    return parentElement;
}

/**
 * @description Check if current active element is part of a sub menu
 *
 * @param {element} activeElement
 * @returns
 */
function isSubMenu(activeElement) {
    const parentUl = getClosestParentTag(activeElement, 'UL');
        
    if (parentUl.hasAttribute('data-submenu')) {
        return true;
    }

    return false;
};

/**
 * @description Check if current focused element has sub menu
 *
 * @param {element} activeElement
 * @param {object} classList
 * @returns
 */
function hasSubMenu(activeElement, classList) {
    const parentLi = getClosestParentTag(activeElement, 'LI');

    if (parentLi.classList.contains(classList.hasChildren)) {
        return true;
    }

    return false;
}

function getSiblings(elem) {
    let siblings = [];
    let sibling = elem.parentNode.firstChild;
        
    while (sibling) {
        if (sibling.nodeType === 1 && sibling !== elem & sibling.classList.contains('nav-item--children')) {
            siblings.push(sibling);
        }
        sibling = sibling.nextSibling    
    }
    return siblings;
};


//------------------- Mouse Navigation --------------------//

const menuParents = document.querySelectorAll(
    '[data-menu="main"] .nav-item--children > .menu-link'
);

// TODO: Refactor to use foreach or for in
for (let i = 0; i < menuParents.length; i++) {
    menuParents[i].addEventListener('click', function(e) {
        addLazyClass(menuParents[i].nextElementSibling);
        const siblings = getSiblings(this.parentNode);
        // TODO: Refactor to use foreach or for in
        for (let i = 0; i < siblings.length; i++) {
            siblings[i].classList.remove('nav-item--open');
            siblings[i].querySelector('[data-menu="submenu"]').removeAttribute('aria-hidden');
            siblings[i].querySelector('[data-menu="submenu"]').classList.remove('nav__active');
        }
        this.parentNode.classList.toggle('nav-item--open');
        this.parentNode.querySelector('[data-menu="submenu"]').toggleAttribute('aria-hidden');
        this.parentNode.querySelector('[data-menu="submenu"]').classList.toggle('nav__active');
        document.activeElement.blur();

    });
}


var repositionDropdown = function () {
    navigationDropdowns.forEach(function(navigationDropdownEl) {
        let dropdown = navigationDropdownEl.nextElementSibling;
        let position = dropdown.getBoundingClientRect();
        if (position.right > document.documentElement.clientWidth) {
            dropdown.style.marginLeft = '-' + (position.right - document.documentElement.clientWidth) + 'px';
        }
    });
}

//------------------- Navigation Dropdowns --------------------//

const navigationDropdowns = document.querySelectorAll('.navigation-dropdown');
navigationDropdowns.forEach(function(navigationDropdownEl) {
    navigationDropdownEl.addEventListener('click', function(event) {
        event.preventDefault();

        var eventTarget = event.target;
        var navigationDropdownsArray = Array.from(navigationDropdowns);

        if (eventTarget.nodeName != "A") {
            eventTarget = event.target.closest('a.navigation-dropdown');
        }

        const navigationChevron = eventTarget.querySelector('[data-chevron]');
        const navigationIcon = eventTarget.querySelector('[data-icon]');
        const navigationChevronArrow = eventTarget.querySelector('svg');
        const navigationContent = eventTarget.nextElementSibling;

        if (!navigationDropdownEl.parentNode.querySelector('div').classList.contains("dropdown-hidden")) {
            navigationDropdownEl.querySelector('[data-chevron]').classList.remove('bg-green');
            navigationDropdownEl.parentNode.querySelector('div').classList.add('dropdown-hidden');
            navigationDropdownEl.parentNode.querySelector('div').style.maxHeight = '0';
            navigationDropdownEl.parentNode.querySelector('div').setAttribute('aria-hidden', true);
            navigationDropdownEl.parentNode.querySelector('svg').setAttribute("transform", "rotate(0)");

        } else {
            const siblings = getSiblings(this.parentNode);
            for (let i = 0; i < menuParents.length; i++) {
                siblings[i].classList.remove('nav-item--open');
                siblings[i].querySelector('[data-menu="submenu"]').removeAttribute('aria-hidden');
                siblings[i].querySelector('[data-menu="submenu"]').classList.remove('nav__active');
                document.activeElement.blur();
            }

            const navigationContentVisible = document.querySelectorAll('[data-content]:not(.hidden)');
            if (navigationContentVisible.length) {
                for (let i = 0; i < navigationContentVisible.length; i++) {
                    navigationContentVisible[i].classList.add('dropdown-hidden');
                    navigationContentVisible[i].style.maxHeight = '0';

                    const navigationDropdown = navigationContentVisible[i].parentNode;

                    if (navigationDropdown) {
                        const navigationChevron = navigationDropdown.querySelector('[data-chevron]');
                        const navigationChevronArrow = navigationDropdown.querySelector('svg');
                        const navigationIcon = navigationDropdown.querySelector('[data-icon]');

                        if (navigationChevron) {
                            navigationChevron.classList.remove('bg-green');
                        }

                        if (navigationIcon) {
                            navigationIcon.classList.remove('active');
                        }

                        if (navigationChevronArrow) {
                            navigationChevronArrow.setAttribute("transform", "rotate(0)");
                        }
                    }
                }
            }


            if (navigationChevron) {
                navigationChevron.classList.add('bg-green');
            }

            if (navigationIcon) {
                navigationIcon.classList.add('active');
            }

            if (navigationChevronArrow) {
                navigationChevronArrow.setAttribute("transform", "rotate(180)");
                navigationChevronArrow.style.marginTop = 0;
            }

            if (navigationContent) {
                navigationContent.classList.remove('dropdown-hidden');
                navigationContent.style.maxHeight = '1000px';
            }
        }

    });

    const level1Children = navigationDropdownEl.children;

    for (let i = 0; i < level1Children.length; i++) {
        level1Children[i].addEventListener('click', function(e) {
            const parentNodeAnchor = event.target.parentNode;
            parentNodeAnchor.dispatchEvent(new Event('click'));
        });
    }
    repositionDropdown();
});

window.addEventListener('resize', function(event) {
    repositionDropdown();
}, true);


document.body.addEventListener('click', function(event){
    repositionDropdown();
    var targetEvent = event.target;

    var navigationDropdownsArray = Array.from(navigationDropdowns);
    if (navigationDropdownsArray.includes(targetEvent) ) {
        return;
    }

    const navigationContent = document.querySelector('[data-content]:not(.hidden)');
    if (!navigationContent || navigationContent.contains(targetEvent)) {
        return;
    }

    const navigationChevronArrowActive = document.querySelector('[data-chevron].bg-green');
    if (targetEvent == navigationChevronArrowActive || navigationChevronArrowActive.contains(targetEvent)) {
        return;
    }

    const navigationIcon = document.querySelector('[data-icon].active');
    if (targetEvent == navigationIcon || navigationIcon.contains(targetEvent)) {
        return;
    }

    if (navigationContent) {
        navigationContent.classList.add('dropdown-hidden');
        navigationContent.style.maxHeight = '0';

        const navigationDropdown = navigationContent.parentNode;

        if (navigationDropdown) {
            const navigationChevron = navigationDropdown.querySelector('[data-chevron]');
            const navigationChevronArrow = navigationDropdown.querySelector('svg');
            const navigationIcon = navigationDropdown.querySelector('[data-icon]');

            if (navigationChevron) {
                navigationChevron.classList.remove('bg-green');
            }

            if (navigationIcon) {
                navigationIcon.classList.remove('active');
            }

            if (navigationChevronArrow) {
                navigationChevronArrow.setAttribute("transform", "rotate(0)");
            }
        }
    }
});
