import axios from "axios/dist/axios"

const init = () =>
{
	// Check document for delivery message elements
	const els = document.querySelectorAll('[data-delivery-message]')

	// If at least one exists...
	if (els.length)
	{
		// Every 10 seconds, make ajax call to get new delivery message
		setInterval( () => {

			axios.get('/actions/orders/default/get-delivery-message')
			.then(function (response)
			{
				// If response ok
				if (response.status === 200)
				{
					// Loop through delivery message elements and insert new message from response
					for (const el of els)
					{
						el.innerHTML = response.data
					}
				}
			})
			.catch(function (error)
			{
				console.log(error)
			})

		}, 10000)
	}
}

export default init