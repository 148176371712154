import * as Utils from '../modules/utilities';

const accordionItems = document.querySelectorAll('[data-accordion]');
const accordionContentPanes = document.querySelectorAll('[data-accordion-body]');
    
for (let i = 0; i < accordionItems.length; i++) {
    const accordionHeading = accordionItems[i].querySelector('[data-accordion-header]');
    accordionHeading.addEventListener('click', accordionControls);  
    
    if (accordionHeading.hasAttribute('data-accordion-button')) {
        accordionHeading.addEventListener('click', scrolltoContent);
    }    
}

function accordionControls(event)
{
    for (let i = 0; i < accordionContentPanes.length; i++) {
        
        const target = event.target.getAttribute('aria-controls');
        const panelID = accordionContentPanes[i].id;
        
        if (panelID === target) {
            Utils.toggleElement(accordionContentPanes[i]); 
        }
    	
    }
};

function scrolltoContent(event)
{
    const buttonID = event.target.dataset.accordionButton;
    const target = document.getElementById(buttonID);
    
    target.scrollIntoView({
        behavior: "smooth", 
        block: "center", 
        inline: "nearest"
    });
};

