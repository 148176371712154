export function showElement(element) 
{
    element.previousElementSibling.setAttribute('aria-expanded', true);
    element.previousElementSibling.querySelector('span').classList.add('transform', 'rotate-45');
    element.classList.add('block');
    element.classList.remove('hidden');
}

export function hideElement(element) 
{
    element.previousElementSibling.setAttribute('aria-expanded', false);
    element.previousElementSibling.querySelector('span').classList.remove('transform', 'rotate-45');
    element.classList.remove('block');
    element.classList.add('hidden');
}

export function toggleElement(element)
{
    if (element.classList.contains('block')) {
		hideElement(element);
		return;
	}
	
	showElement(element);
}