/**
* @title: Show More/Less
* @description: Show More/Less when clicking link
*/

function showHide() {

    let showButton = document.querySelectorAll('[data-show-button]');
    if (showButton.length) {
        
        for (let i = 0; i < showButton.length; i++) {
        
            const showElement = showButton[i].previousElementSibling;
            let showOpen = false;
    
            showButton[i].addEventListener('click', function() {
                showElement.classList.toggle('active');
    
                showOpen = (showOpen === false) ? true : false;
    
                // Text is taken from a data attr due to language difference
                const openText = showButton[i].dataset.open;
                const closeText = showButton[i].dataset.close;
    
                if (showOpen) {
                    showButton[i].innerText = closeText;
                } else {
                    showButton[i].innerText = openText;
                }
    
            });

        }

    }


}

export default showHide;
