/** Cookies **/

const globalWrapper = document.getElementById('global-wrapper');
const cookieNoticeEl = document.getElementById('cookie-notice');
const cookieAcceptBtn = document.getElementById('cookie-notice__accept');
const cookieDismissBtn = document.getElementById('cookie-notice__dismiss');
const cookieUpdateForm = document.getElementById('cookie-form');
const cookieUpdateCheckbox = document.getElementById('cookie-form__checkbox');
const cookieNoticeReject = document.getElementById('cookie-form__update');

/**
* title Set Cookies
* description Set certain cookies for the local Storage
* param {string} cname
* param {bool} cvalue
* param {int} exdays
 */
function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  const expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {

        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        setCookie(name, '', -1);
    }
}

function acceptCookies() {
    setCookie('cookies-accepted', true, 365);
    localStorage.setItem('cookies', 'true');
    cookieNoticeEl.style.display = 'none';
    globalWrapper.classList.remove('global-wrapper--cookies');

    // if (reload) {
    //     window.location.reload();
    // }

}

function rejectCookies() {
    localStorage.setItem('cookies', 'false');
    deleteAllCookies();
    cookieNoticeEl.style.display = 'none';
    globalWrapper.classList.add('global-wrapper--cookies');

    // if (reload) {
    //     window.location.reload();
    // }

}

/**
 * title Initiate Cookies
 * description Initiate the Cookie Notice if local storage says they are not set
 */
function cookieNotice() {
    cookieNoticeEl.style.display = 'block';
    const cookieStorage = localStorage.getItem('cookies');

    if (cookieStorage === 'false') {
        localStorage.setItem('cookies', 'false');
        rejectCookies();
    } else if (cookieStorage === 'true') {
        acceptCookies();
    }

    return;
}
if (cookieAcceptBtn) {
  cookieAcceptBtn.addEventListener('click', function(e) {
      e.preventDefault();
      acceptCookies(true);
  });
}
if (cookieDismissBtn) {
  cookieDismissBtn.addEventListener('click', function(e) {
    e.preventDefault();
    rejectCookies(false);
  });
}

if (cookieUpdateForm) {
    cookieNoticeReject.addEventListener('click', function(e) {
        e.preventDefault();
        if (!cookieUpdateCheckbox.checked) {
            rejectCookies(true);
        } else {
            acceptCookies(true);
        }
    });
}


export default cookieNotice;

