const bannerNotificationEl = document.getElementById('notification-banner');

if (document.body.contains(bannerNotificationEl)) {
    const hiddenNotificationList = localStorage.getItem("hidden-notification");
    const bannerNotificationBtn = document.getElementById('banner-dismiss');
    let arrNotifications = JSON.parse(hiddenNotificationList);
    const bannerId = parseInt(bannerNotificationEl.getAttribute("data-banner-id"));

    if (bannerNotificationBtn) {
        bannerNotificationBtn.addEventListener('click', function(e) {
            e.preventDefault();

            if (arrNotifications === null) {
                arrNotifications = [];
            }

            arrNotifications.push(bannerId);
            localStorage.setItem("hidden-notification", JSON.stringify(arrNotifications));
            bannerNotificationEl.classList.add('hidden');
        });
    }
        
    if (hiddenNotificationList !== null) {
        if (hiddenNotificationList.includes(bannerId)) {
            bannerNotificationEl.classList.add('hidden');
        } else {
            bannerNotificationEl.classList.add('block');
            bannerNotificationEl.classList.remove('hidden');   
        }
    } else {        
        bannerNotificationEl.classList.add('block');
        bannerNotificationEl.classList.remove('hidden');
    }
}