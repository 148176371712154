import LazyLoad from "vanilla-lazyload";

var lazyLoadMenu = new LazyLoad({
    elements_selector: ".menu-lazy",
    cancel_on_exit: false,
    unobserve_entered: true,
});

function addLazyClass(element) {
    const images = element.querySelectorAll('.menu-image');
    images.forEach(function(item) {
        if (!item.classList.contains('menu-lazy')) {
            item.classList.add('menu-lazy'); 
            lazyLoadMenu.update(); 
        }     
    });
}

/**
 * @title Open Navigation
 * @description This will open up the mobile menu
 * @param {string} elementToOpen
 * @param {string} elementToClose
 */

function openNavigation(elementToOpen, elementToClose) {
    const currentMenu = document.getElementById('main-menu').dataset.currentMenu;        
    elementToOpen = (elementToOpen === undefined ? currentMenu : elementToOpen);

    document.getElementById('main-menu').classList.add('translate-y-0');
    document.querySelector('.site-header').classList.add('site-header--open');

    setTimeout(function() {
        document.documentElement.classList.add('menu-opening');
    }, 100);

    const targetElement = document.getElementById(elementToOpen);
        
    if (targetElement) {
        if (elementToOpen !== 0 && document.documentElement.classList.contains('menu-opening')) {
            setTimeout(function() {
                targetElement.classList.add('translate-x-0');
                targetElement.classList.remove('-translate-x-full');
                targetElement.classList.add('translate-x-full');
            }, 100);
    
            if (typeof elementToClose !== 'undefined') {
                const targetParentElement = document.getElementById(elementToClose);            
                targetParentElement.classList.remove('translate-x-0');
            }
            addLazyClass(targetElement);
        } else {
            targetElement.classList.remove('translate-x-full');
            targetElement.classList.remove('-translate-x-full');        
        }
    }
}

/**
 * @title Close Navigation
 * @description This will close the mobile menu
 * @param {string} targetElementClass
 */

function closeNavigation(targetElementClass) {
    // TODO: Is this just resetting the value of the function param?
    targetElementClass = 'menu-panel';
    const elementToUpdate = document.getElementById('main-menu');

    setTimeout(function() {
        elementToUpdate.classList.remove('translate-y-0');
    }, 150);
    
    document.querySelector('.site-header').classList.remove('site-header--open');
    document.documentElement.classList.remove('menu-opening');

		// TODO: Should refactor this and for loop below to use query selector
    const menuElements = elementToUpdate.getElementsByClassName(targetElementClass);

    if (menuElements) {
        for (let i = 0; i < menuElements.length; i++) {
            setDelay(i);
        }

        function setDelay(i) {
            setTimeout(function() {
                menuElements[i].classList.remove('translate-x-0');
            }, 400);
        }
    }
}

const menuTrigger = document.querySelector('[data-mobile-trigger]');
let menuOpen = false;

if (menuTrigger) {
    menuTrigger.addEventListener('click', function() {
        if (menuOpen == false) {
            openNavigation();
            menuOpen = true;
            menuTrigger.classList.add('open');
        } else {
            closeNavigation();
            menuOpen = false;  
            menuTrigger.classList.remove('open');
        }
    });
}

// TODO: Should refactor to use queryselectorall
let addOpenButtonInteraction = document.getElementsByClassName('menu__item--children');
for (let i = 0; i < addOpenButtonInteraction.length; i++) {
    const link = addOpenButtonInteraction[i].querySelector('a');
    link.addEventListener('click', function() {
        let target = this.getAttribute('data-target');
        let parent = this.getAttribute('data-parent');
        
        const targetElement = document.getElementById(parent);
        targetElement.classList.add('-translate-x-full');
        
        openNavigation(target, parent);
    });
}

// TODO: Should refactor to use queryselectorall
let addCloseButtonInteraction = document.getElementsByClassName('menu-back');
for (let i = 0; i < addCloseButtonInteraction.length; i++) {
    addCloseButtonInteraction[i].addEventListener('click', function() {
        let target = this.getAttribute('data-target');
        let parent = this.getAttribute('data-parent');        

        if (target === 'menu-0') {
            closeNavigation(); // if its top-level then we should close the menu.
        } else {
            openNavigation(parent, target);
        }
    });
}

let mobileDropdowns =   document.getElementsByClassName('mobile-dropdown');
for (let i = 0; i < mobileDropdowns.length; i++) {
    mobileDropdowns[i].addEventListener('click', function() {
        if (this.nextElementSibling.classList.contains('open')) {
            this.nextElementSibling.style.maxHeight = '0';
            this.nextElementSibling.classList.remove('open');
            this.querySelector('[data-chevron]').classList.remove('bg-green');
            this.querySelector('svg').setAttribute("transform", "rotate(0)");
        } else {
            this.nextElementSibling.style.maxHeight = '1000px';
            this.nextElementSibling.classList.add('open');
            this.querySelector('[data-chevron]').classList.add('bg-green');
            this.querySelector('svg').setAttribute("transform", "rotate(90)");
        }
    });
}

export { openNavigation, closeNavigation };
